import React from "react"
import styled from "styled-components"

type TextColumnsProps = {
  children: HTMLElement
  offset?: boolean
}

export const TextColumns = (props: TextColumnsProps) => {
  return <StyledGrid offset={props.offset}>{props.children}</StyledGrid>
}

const StyledGrid = styled.div<Partial<TextColumnsProps>>`
  display: grid;
  grid-template-columns: ${props =>
    props.offset ? "1fr 1.5fr 1.5fr" : "1fr 1fr 1fr"};
  grid-template-rows: auto;
  grid-gap: ${props => (props.offset ? undefined : "40px")};
  margin: 160px 0px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin: ${props => (props.offset ? "68px 0px 100px 0px" : "100px 0px")};
  }
`
